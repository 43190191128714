import styled from "styled-components"

const FeaturesWrapper = styled.section`
  height: 100%;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;

  .flex-b1 {
    width: 100%;
    height: 20%;
  }
  .flex-b2 {
    width: 100%;
    height: 80%;
  }
  .cols-prod {
  }
  .react-reveal {
    display: flex;
    justify-content: center;
  }
  .text-flex {
    width: calc(45% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      justify-content: center;
      width: 35%;
    }
    @media (max-width: 425px) {
      justify-content: center;
      width: 70%;
    }
  }
  .title-flex {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1425px) {
      width: 50%;
    }
    @media (max-width: 1285px) {
      width: 55%;
    }

    @media (max-width: 500px) {
      width: 70%;
    }
    @media (max-width: 400px) {
      width: 80%;
    }
    @media (max-width: 335px) {
      width: 90%;
    }
  }
`

const SectionImage = styled.div`
  height: 100%;
  padding-top: 120px;
  img {
    max-width: 93%;

    @media (max-width: 1200px) {
      display: none;
    }
  }
  .objectWrapper {
    margin-right: auto;

    .dashboardWrapper {
      left: 0;
    }
  }
`
const SectionSlider = styled.div`
width:100%;
height:100%;
.img-flex{
  width:100px;
  @media (max-width: 1024px) {
      width: 94px !important;
    }
  @media (max-width: 768px) {
      width: 80px !important;
  }  
  @media (max-width: 643px) {
      width: 75px !important;
  } 
  @media (max-width: 575px) {
      width: 65px !important;
  } 
  @media (max-width: 510px) {
      width: 50px !important;
  }
  @media (max-width: 320px) {
      width: 41px !important;
  }
}
.flexCols{


  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;

	align-content: center;
  
    }
  
    .flexCols-b1{
  height:40%;
  width:40%;
  
  @media (max-width: 1200px) {
      display: none;
    }
  
    }
    .flexCols-b2{
      margin-top:30px;
      width:60%;
      /* height:0; */
      @media screen and (min-width: 426px) and (max-width: 1199px) {
        margin-top:60px;
        width:80%;
     }
      @media (max-width: 425px) {
        width:100%;
      /* height:900px; */
    }
    }
    .flex-desc {
      display : flex;
      flex-direction : column;
      @media (max-width: 425px) {
        flex-direction : row;
        flex-wrap: wrap;
        width:100%;

      }
    }
.flex-wrap{
  @media (max-width: 425px) {
  width:50%;
  }
}
.text-size-min{
  @media (max-width: 425px) {
  font-size:11px;
  }
}
.dXhKQU {
  @media (max-width: 425px) {
    margin-top: -12px;
  }

}
  .objectWrapper {
    margin-right: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 4vw;
      left: 0;
    }
  }
  .rc-tabs-ink-bar {
    background-color:#ff862d !important;
   
  }
  .rc-tabs-tab-next-icon ,.rc-tabs-tab-prev-icon{
    font-weight: 700 !important;

  }
  
  .rc-tabs-top .rc-tabs-tab-next ,.rc-tabs-top .rc-tabs-tab-prev{
    right: 2px;
    top: 18px;
    border: 1;
    color: #ff862d;
    width: 37.5px;
    height: 37.5px;
    /* margin-right: 30px; */
    border-radius: 45%;
    justify-content: center;
    box-shadow: 3px 1.5px 4px 2px rgba(194, 207, 224, 0.73);

    
  }
  .rc-tabs-tab {
    @media(max-width: 1024px) {
        padding: 8px 10px;
    }

    img {
      filter: grayscale(1);
      opacity: 0.5;
      transition: 0.3s ease-in-out;
    }
  }
  .rc-tabs-tab-active, .rc-tabs-tab-active:hover {
    img {
      filter: grayscale(0);
      opacity: 1;
      // width:150px
    }
  }
  .col2{
    @media screen and (min-width: 1025px) and (max-width: 1095px) {
      margin-top:-100%;
    }
    @media screen and (min-width: 1095px) and (max-width: 1140px) {
      margin-top:-96%;
    }
    @media screen and (min-width: 1140px) and (max-width: 1228px) {
      margin-top:-90%;
    }
    @media screen and (min-width: 1229px) and (max-width: 1400px) {
      margin-top:-78%;
    }
    @media screen and (min-width: 1401px) and (max-width: 1440px) {
      margin-top:-72%;
    }
    @media (max-width: 375px) {
      margin-top :-290%;

    }
    @media (max-width: 320px) {
      margin-top :-355%;
     
    }
  }
  .rc-tabs-nav {
    display:flex;
    margin-left:auto;
    margin-right:auto;
    justify-content:right;
    align-items:right;
    
    /* @media  (min-width: 1600px){
      margin-left :0.8%;
    }  */
    /*
    @media  (max-width: 1599px){
      margin-left :0.7%;
    }
    @media  (max-width: 1299px){
      margin-left :0.35%;
    }
    @media  (max-width: 1199px){
      margin-left :0.18%;
    }
    @media  (max-width: 1023px){
      margin-left :0.1%;
    }
    @media  (max-width: 899px){
      margin-left :0.08%;
    }
    @media  (max-width: 768px){
      margin-left :0.05%;
    }
    @media  (max-width: 600px){
      margin-left :0%;
    } */
    
    

  }
  .rc-tabs-nav-container{
    margin-left :40%;
    @media  (max-width: 1200px){
      display : flex;
      align-items: center;
      justify-content : center;
      margin-left :0%;

    }

  }
  .rc-tabs-top .rc-tabs-nav-scroll ,.rc-tabs-top .rc-tabs-nav-wrap {
    display : flex;
  }

  .rc-tabs-top .rc-tabs-nav-container-scrolling {
  
    /* @media (max-width: 1440px) {
      
      margin-left: 40px;
      margin-right: 80px;
    }
    @media (max-width: 1024px) {
     
      margin-left: 50px;
      margin-right: 50px;
    }
    @media (max-width: 768px) {
      margin-left: 60px;
      margin-right: 60px;
    }
   
    @media (max-width: 425px) {
      margin-left: 75px;
      margin-right: 75px;
    }
    @media (max-width: 375px) {
      margin-left: 50px;
      margin-right: 50px;
    }
    @media (max-width: 321px) {
      margin-top :45px;
      margin-left: 35px;
      margin-right: 2px;
    } */

}
.rc-tabs-top .rc-tabs-tab {
  margin-right: 0px;
  @media (min-width: 1440px) {
    margin-right: 30px;
  }
}
.rc-tabs-top .rc-tabs-bar {
  border-bottom: 1px solid #f3f3f3;
  /* width: 70%;
  margin-left: 36%; */
  display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	justify-content: center;
  /* @media screen and (min-width: 1441px) and (max-width: 1580px) {
    margin-left:27%;
  }
  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    width: 100%;
    margin-left: 15%;
    }
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    width: 100%;
    margin-left: 12%;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
    margin-left: 10%;
    } */
}
.rc-tabs {
  box-sizing: border-box;
  position: unset;
  overflow: hidden;
}
.rc-tabs-top{
  border-bottom : none;
}
.rc-tabs-top .rc-tabs-content {
  @media (max-width: 1440px) {
    width: 95%;
    
  }
  @media (max-width: 425px) {
    width: 100%;
    padding: 27px;
  }
 
.buttom-features{
  margin-bottom:5%;
  @media (max-width: 769px) {
    margin-top :2%;
    margin-left : 5%;}
  @media (max-width: 425px) {
    margin-left :35%;
    margin-bottom :10%;
  }
}

`
const TitleContainer = styled.div`
  font-size: 30px;
  margin-top: 12%;
  @media (max-width: 1801px) {
    font-size: 40px;
    margin-top: 0%;
  }
  @media (max-width: 1440px) {
    font-size: 40px;
    margin-top: 0%;
  }
  @media (max-width: 1200px) {
    font-size: 35px;
  }
  @media (max-width: 1024px) {
    margin-top: 0%;
  }
  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    margin-top: -144%;
  }
  @media (max-width: 425px) {
    font-size: 25px;
    text-align: center;
  }
`

export { FeaturesWrapper, SectionImage, SectionSlider, TitleContainer }
