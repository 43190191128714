import styled from "styled-components"
import { themeGet } from "styled-system"

const SectionWrapper = styled.section`
  width: 100%;
  padding: 81px 0 50px;
  @media only screen and (max-width: 1440px) {
    padding: 70px 0 50px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 50px 0 30px;
  }
  @media only screen and (max-width: 991px) {
    padding: 40px 0 10px;
  }
  .card-flex {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: flex-start;
    align-content: center;
  }
  .card-width {
    width: 375px;
  }
  .showMobile {
    display: none;
    @media (max-width: 1300px) {
      display: block;
    }
    @media (max-width: 375px) {
      margin-left: -4%;
    }
  }

  .showWeb {
    visibility: visible;
    @media (max-width: 1300px) {
      display: none;
    }
  }
  #box .react-reveal {
    display: flex;
    justify-content: center;
  }
  .text-flex {
    width: calc(45% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      justify-content: center;
      width: 35%;
    }
    @media (max-width: 425px) {
      justify-content: center;
      width: 70%;
    }
  }
  .title-flex {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1425px) {
      width: 50%;
    }
    @media (max-width: 1285px) {
      width: 55%;
    }

    @media (max-width: 500px) {
      width: 70%;
    }
    @media (max-width: 400px) {
      width: 80%;
    }
    @media (max-width: 335px) {
      width: 90%;
    }
  }
`

export const SectionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: self-start;
  }
`

export const TitleArea = styled.div`
  max-width: 631px;
`

export const Text = styled.p`
  font-size: 18px;
  line-height: 40px;
  margin-bottom: 0;
  @media only screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 32px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 15px;
    line-height: 30px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 15px;
    line-height: 28px;
  }
`

export const PostArea = styled.div`

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -50px;
  padding: 37px 40px 40px;
  // border-radius: 5px;
  // border: 1px solid ${themeGet("colors.lightBorder", "#F2F4F7")};
  @media only screen and (max-width: 1440px) {
    margin: 0 -35px;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0 -30px;
  }
  @media only screen and (max-width: 991px) {
    margin: 0 -20px;
  }
  .card-border{
    padding-left: 40px;
    padding-right: 40px;

    @media only screen and (max-width: 1440px) {
      padding-left: 10px;
      padding-right: 10px;

    }
    // border-radius: 3px;
    // border: 1.5px solid ${themeGet("colors.lightBorder", "#F2F4F7")};
  }

  .blog_post {
    width: calc(25% - 70px);
    margin: 0 30px;
    overflow: hidden;
    box-shadow: 0 0 100px rgba(175, 182, 194, 0.2);
    background-color: ${themeGet("colors.white", "#ffffff")};
    @media only screen and (max-width: 1440px) {
      width: calc(50% - 70px);
      margin: 0 35px 30px;
    }
    @media only screen and (max-width: 1360px) {
      width: calc(50% - 60px);
      margin: 0 30px 30px;
    }
    @media only screen and (max-width: 991px) {
      width: calc(50% - 40px);
      margin: 0 20px 30px;
    }
    @media only screen and (max-width: 667px) {
      width: 100%;
    }

    .thumbnail {
      img {
        width: 100%;
        height: 350px;
        object-fit: cover;
      }
    }
    
    .content {
      padding: 42px 30px 30px;
      @media only screen and (max-width: 1360px) {
        padding: 30px 40px 45px;
      }
      @media only screen and (max-width: 991px) {
        padding: 20px 30px 35px;
      }
     
      .title {
        color: rgba(53, 53, 53, 0.95);
        line-height: 1.5;
        letter-spacing: -0.23px;
        text-align: left;
        font-size: 23px;
        line-height: 40px;
        font-weight: 600;
        margin: 0 0 24px;
        opacity: 0.95;
        @media only screen and (max-width: 1440px) {
          font-size: 22px;
          line-height: 36px;
        }
        @media only screen and (max-width: 1360px) {
          font-size: 20px;
          line-height: 34px;
        }
        @media only screen and (max-width: 991px) {
          font-size: 18px;
          line-height: 30px;
          margin: 0 0 15px;
        }
      }

      .excerpt {
        font-size: 18px;
        line-height: 32px;
        margin: 0 0 27px;
        text-align: left;
        @media only screen and (max-width: 1600px) {
          font-size: 17px;
          line-height: 28px;
        }
        @media only screen and (max-width: 1440px) {
          font-size: 16px;
        }
        @media only screen and (max-width: 1360px) {
          font-size: 15px;
        }
        @media only screen and (max-width: 991px) {
          margin-bottom: 20px;
        }
      }
    }

    &:nth-child(2) {
      .content {
        .title {
          padding-right: 78px;
          @media only screen and (max-width: 991px) {
            padding-right: 0;
          }
        }
      }
    }
  }
`

export const NewsMeta = styled.div`
  flex: 0 0 100%;
  max-width: calc(100%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid #dddddd;
  padding-top: 20px;
  justify-content: space-between;
  @media (max-width: 990px) {
    margin-top: 50px;
    max-width: calc(100% - 100px);
  }
  @media (max-width: 575px) {
    margin-top: 30px;
    max-width: 100%;
    margin-bottom: 15px;
  }
`

export const MetaItem = styled.span`
  margin-right: 45px;
  font-size: 16px;
  color: #dddddd;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  @media (max-width: 425px) {
    font-size: 14px;
    margin-right: 25px;
  }
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }

  > i {
    margin-right: 5px;
    color: #dddddd;
  }
`
export const CarouselWrapper = styled.div`
  margin-top: -30px;
  margin-right: -33px;
  margin-bottom: -50px;
  @media (max-width: 425px) {
    margin-top: unset;
    margin-right: unset;
    margin-bottom: unset;
  }
  .fb_iframe_widget_fluid span{
    @media (max-width: 425px) {
    display: flex;
    justify-content: center;}
    @media (max-width: 375px) {
      margin: 0 3%;}
  }
 iframe {
    @media (max-width: 425px) {
     max-width :77% !important;
     min-width :unset !important;
    }
    @media (max-width: 375px) {
     max-width: 70% !important;
     min-width :unset !important;
    }
  }

    .glide__track {
      @media (max-width: 425px) {
        overflow: unset;
      }

      @media (max-width: 375px) {
        margin-left: -5%;
      }
    }
    .cardSize {
      width: 375px;
      height: 0;
      @media (max-width: 1100px) {
        height: 0;
      }
      @media (max-width: 425px) {
        height: 0;
      }
    }
    .fb-post {
      width: 375px;
      @media (max-width: 500px) {
        height: 0;
      }
      @media (max-width: 375px) {
        margin: 0% 3%;
      }
    }
    .review-card {
      display: flex;
      justify-content: center;
      padding: 37px 40px 40px;
      border-radius: 5px;
      border: 1px solid ${themeGet("colors.lightBorder", "#F2F4F7")};
      transition: all 0.3s ease;
      @media only screen and (max-width: 480px) {
        /* padding: 25px 25px 30px; */
        padding: unset;
        border-radius: unset;
      }
      @media only screen and (max-width: 480px) {
        border: unset;
      }

      @media only screen and (max-width: 375px) {
        border: none;
      }
      &:hover {
        /* box-shadow: 0 10px 50px rgba(38, 78, 118, 0.1); */
      }

      h3 {
        color: ${themeGet("colors.quoteText", "#343D48")};
        font-size: 16px;
        line-height: 33px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      p {
        color: ${themeGet("colors.quoteText", "#343D48")};
        font-size: 18px;
        line-height: 32px;
        font-weight: 300;
        @media only screen and (max-width: 667px) {
          font-size: 16px;
          line-height: 30px;
        }
      }

      .card-footer {
        display: flex;
        align-items: center;
        margin-top: 33px;
        @media only screen and (max-width: 480px) {
          align-items: flex-start;
          margin-top: 30px;
        }

        .image {
          flex-shrink: 0;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            box-shadow: 0 6px 30px rgba(39, 79, 117, 0.2);
          }
        }

        .reviewer-info {
          width: calc(100% - 50px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 19px;
          @media only screen and (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }

        .content {
          h4 {
            font-size: 16px;
            font-weight: 500;
            margin: 0 0 7px;
            color: ${themeGet("colors.headingColor", "#0F2137")};
            @media only screen and (max-width: 480px) {
              margin-bottom: 3px;
            }
          }
          p {
            font-size: 14px;
            line-height: 24px;
            color: rgba(15, 33, 52, 0.6);
            font-weight: 400;
            margin: 0;
          }
        }
      }
    }
  }

  .glide__controls {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    > div {
      position: absolute;
      top: calc(50% - 70px / 2);
      &.glide__prev--area {
        /* left: -24px; */
        @media only screen and (max-width: 480px) {
          /* left: -30px; */
        }
        @media only screen and (max-width: 375px) {
          /* left: -12px; */
          top: calc(35% - 50px / 2);
        }
      }
      &.glide__next--area {
        right: 8px;
        @media only screen and (max-width: 480px) {
          right: 0px;
        }
        @media only screen and (max-width: 375px) {
          right: -11px;
          top: calc(35% - 50px / 2);
        }
      }
      .reusecore__button {
        &:hover {
          box-shadow: ${themeGet(
            "colors.primary",
            "#F6C416"
          )} 0px 12px 24px -10px;
        }
      }
    }
  }

  .glide {
    .glide__controls {
      opacity: 1;
      visibility: visible;
    }
  }
`

export default SectionWrapper
