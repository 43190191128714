import styled from "styled-components"
import { themeGet } from "styled-system"

const AboutUsSectionWrapper = styled.section`
  padding: 80px 0 0;
  overflow: hidden;

  @media (max-width: 990px) {
    padding: 60px 0 40px 0;
  }

  .col {
    align-self: center;
  }

  .group-gallery {
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    .col1 {
      width: calc(45% - 30px);
      margin-left: 24%;
      margin-top: 25%;
      @media (max-width: 1024px) {
        margin-left:60%;
      }

      .gatsby-image-wrapper {
        margin-bottom: 30px;
      }
    }
    .col2 {
      width: calc(37% - 30px);
      align-self: center;
      margin-top: 5%;
      margin-left: 10%;

    }
    .col3 {
      width: calc(35% - 30px);
      margin-top: -5%;
      margin-left: 2%;
      @media (max-width: 1024px) {
        margin-top:5%;
        margin-left: 8%;
      }
      @media (max-width: 425px) {
        justify-content:center;
        margin-left: 15%;
        width:100%
      }
     
  }
    }
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 30px;
      object-fit: contain;
      @media (max-width: 425px) {
        display:none;
      }
      // box-shadow: 0px 0px 250px 0px rgba(39, 79, 117, 0.1);
    }
  }

  .feature__block {
    align-items: center;
    margin-bottom: 14px;
    .icon__wrapper {
      color: ${themeGet("colors.primary", "#10ac84")};
      margin-right: 10px;
    }
    .content__wrapper {
      h2 {
        margin-bottom: 0;
      }
    }
    @media (max-width: 1550px) {
      margin-left:25%;
      width:120%;
      margin-top:-70%;
    }
    @media (max-width: 768px) {
      margin-left: 25%;
      width: 120%;
      margin-top:-50%;
    }
    @media (max-width: 425px) {
      margin-left: -88px;
      width: 197%;
      margin-top: -50%;
      
    }
    @media (max-width: 375px) {
      margin-left: -67px;
      width:120%;

    }
    @media (max-width: 320px) {
      margin-left: -48px;
      width:120%;

    }
  }

  .reusecore__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size:14px;
  @media only screen and (max-width: 1440px) {
    width: 60%;
    font-size:17px;
  }
  @media only screen and (max-width: 1024px) {
    width: 55%;
    font-size:13px;
  }
  @media only screen and (max-width: 768px) {
    width: 75%;
    font-size:13px;
  }
  @media (max-width: 425px) {
    width: 75%;
    font-size:13px;
  }
  @media (max-width: 375px) {
    font-size:13px;

  }
  @media (max-width: 318px) {
    font-size:15px;

  }
  }
`
export const CustomerDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 126%;
  margin-top: 6%;
  margin-left: -12%;
  font-size: 28px;

  @media only screen and (max-width: 1024px) {
    font-size: 20px;
    margin-left: -12.6%;
  }
  @media only screen and (max-width: 768px) {
    width: 122.5%;
    margin-left: -11%;
  }
  @media (max-width: 425px) {
    width: 122%;
    margin-left: -11%;
  }
  @media (max-width: 375px) {
    margin-left: -12%;
    margin-top: 12%;
  }
  @media (max-width: 320px) {
    margin-left: -10.5%;
  }
`

export default AboutUsSectionWrapper
