import styled from "styled-components"

const FaqSectionWrapper = styled.section`
  padding: 80px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* background: #fafbff; */
  height: unset;
  @media (max-width: 1024px) {
    padding: 0;
    margin-bottom: 5%;

  }
  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 15%;

  }
  @media (max-width: 425px) {
    height: unset;
    padding: 0;
    margin-bottom: 45%;
  }
  .react-reveal {
    display: flex;
    justify-content: center;
  }
  .text-flex {
    width: calc(59% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      justify-content: center;
    }
    @media (max-width: 425px) {
      justify-content: center;
      width: 100%;
    }
  }
  .flex-title-box {
    text-align: left !important;
    @media (max-width: 1024px) {
      margin-left: 0% !important;
    }
  }
  .margin-btw {
    margin-top: 1% !important;
  }
  .dzgRiW{
    @media (max-width: 425px) {
      margin-top: 15%;
    }
  }
  .title-flex {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1425px) {
      width: 50%;
    }
    @media (max-width: 1285px) {
      width: 55%;
    }

    @media (max-width: 500px) {
      width: 70%;
    }
    @media (max-width: 400px) {
      width: 80%;
    }
    @media (max-width: 335px) {
      width: 90%;
    }
  }

  .flex-b1 {
    width: 69%;
    height: auto;
    /* padding: 120px 0; */
    @media (max-width: 1024px) {
      height: unset;
    }
    @media (max-width: 768px) {
      /* margin-bottom: 10%; */
    }
  }
  .flex-b2 {
    width: 31%;
    height: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    @media (max-width: 1024px) and (min-width: 200px) {
      display: none;
    }
  }
  .faq-section-img {
    margin-top: 0;
    width: 100%;
    height: 80%;
    @media (max-width: 1440px) {
      margin-top: 25%;
    }
  }

  .flex-img-wrapper {
    max-width: none;
    width: 200%;
    margin-left: 91%;
    margin-top: -25%;
  }
  .faq-section-questions {
  }
  .reusecore__accordion {
    max-width: 820px;
    margin: 0 auto;
    // border: 1px solid #ebebeb;

    .accordion__item {
      border-top: 0;
      margin: 3%;
      border: 1px solid #ebebeb;
      box-shadow: 0.7px 3px 12px 0 rgba(194, 207, 224, 0.73);
      border-radius: 10px;
      @media (max-width: 425px) {
        margin: 3% -2% 3% -2%;
      }
      &:last-child {
        border-bottom: 0;
      }

      .accordion__header {
        padding: 20px 30px;
      }

      .accordion__body {
        padding: 5px 30px 20px;
      }
    }
  }
`
export default FaqSectionWrapper
