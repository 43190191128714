import React from "react"
import Modal from "react-modal"
import Button from "../../../components/Button"
import close from "../../../assets/images/generic/close.png"
import './modal.css'
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "75%",
    overflow: "hidden",
    border: " unset",
    background: " unset",
    bordeRadius: " unset",
  },
}
const mobileStyle = {
  content: {
    top: "30%",
    left: "-22%",
    transform: "rotate(90deg)",
    width: "calc(140%)",
    height: "45%",
    overflow: "hidden",
    border: " unset",
    background: " unset",
    bordeRadius: " unset",
  },
}
class ModalVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: window.innerHeight,
      width: window.innerWidth,
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }
  componentDidMount() {
    console.log(this.state.height)
    window.addEventListener("resize", this.updateDimensions)
  }
  updateDimensions = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.open}
          onRequestClose={this.props.closeModal}
          style={this.state.width > 500 ? customStyles : mobileStyle}
        >
          <Button
            onClick={this.props.closeModal}
            className={
              this.state.width > 768 && this.state.height > 882 ? "close_btn" : "close_btn_mobile"
            }
            icon={<img src={close} alt="video" width="15px;" />}
            variant="x"
          />
          <iframe
            src="https://player.vimeo.com/video/349629302?rel=0&autoplay=1"
            width="100%"
            height="100%"
            frameborder="0"
            allow="autoplay; fullscreen"
            mozallowfullscreen
            webkitallowfullscreen
            oallowfullscreen
            msallowfullscreen
            scrolling="no"
          ></iframe>
        </Modal>
      </div>
    )
  }
}

export default ModalVideo
