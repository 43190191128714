import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Container from "../../../components/Container"
import Heading from "../../../components/Heading"
import SocialProfile from "../../../components/SocialProfile"
import Box from "../../../components/Box"
import Text from "../../../components/Text"

import Button from "../../../components/Button"
import { FacebookProvider, EmbeddedPost } from "react-facebook"
import Fade from "react-reveal/Fade"

import GlideCarousel from "../../../components/GlideCarousel"
import GlideSlide from "../../../components/GlideCarousel/glideSlide"

import SectionWrapper, { PostArea, CarouselWrapper } from "./style"

const NewsSection = ({ row, sectionTitle, sectionHeader, sectionSubTitle }) => {
  const fbLink = [
    {
      ref:
        "https://www.facebook.com/agrabiz.de/photos/a.111841287024462/119471846261406/?type=3&theater",
    },
    {
      ref:
        "https://www.facebook.com/agrabiz.de/photos/a.111841287024462/139352097606714/?type=3&theater",
    },
    {
      ref:
        "https://www.facebook.com/agrabiz.de/photos/rpp.111596537048937/119494702925787/?type=3&theater",
    },
  ]

  const glideOptions = {
    type: "slider",
    autoplay: 5000,
    perView: 1,
    gap: 30,
    animationDuration: 800,
    breakpoints: {
      800: {
        perView: 1,
        autoplay: false,
      },
    },
  }

  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        SOCIAL_PROFILES {
          logo {
            publicURL
          }
          url
        }
      }
    }
  `)

  return (
    <SectionWrapper id="news">
      <div {...sectionHeader} id="box">
        <Fade bottom cascade>
          <Text {...sectionSubTitle} content="Agrabiz Community & News" />
          <Heading
            {...sectionTitle}
            className="text-flex"
            content="In unseren sozialen Kanälen informieren wir regelmäßig über unsere Neuigkeiten. Folgen Sie uns schon? Werden Sie Teil unserer Community und helfen Sie uns, unser Produkt noch besser zu machen!"
          />
        </Fade>
      </div>

      <Container fullWidth>
        <PostArea className="showWeb">
          <FacebookProvider appId="1501095116625120">
            <div className="card-flex">
              <div {...row} className="card-border card-width">
                <EmbeddedPost
                  href="https://www.facebook.com/agrabiz.de/photos/a.111841287024462/119471846261406/?type=3&theater"
                  width="375px"
                />
              </div>
              <div {...row} className="card-border card-width">
                <EmbeddedPost
                  href="https://www.facebook.com/agrabiz.de/photos/a.111841287024462/139352097606714/?type=3&theater"
                  width="375px"
                />
              </div>
              <div {...row} className="card-border card-width">
                <EmbeddedPost
                  href="https://www.facebook.com/agrabiz.de/photos/rpp.111596537048937/119494702925787/?type=3&theater"
                  width="375px"
                />
              </div>
            </div>
          </FacebookProvider>
        </PostArea>
        <CarouselWrapper className="showMobile">
          <GlideCarousel
            
            options={glideOptions}
            nextButton={
              <Button
                icon={<i className="flaticon-next" />}
                aria-label="Next"
                variant="fabNews"
              />
            }
            prevButton={
              <Button
                icon={<i className="flaticon-left-arrow" />}
                aria-label="Prev"
                variant="fabNews"
              />
            }
          >
            <Fragment>
              {fbLink.map((item, index) => (
                <GlideSlide key={index} >
                  <div className="review-card">
                    <FacebookProvider appId="1501095116625120">
                      <EmbeddedPost width="375px" href={item.ref} />
                    </FacebookProvider>
                  </div>
                </GlideSlide>
              ))}
            </Fragment>
          </GlideCarousel>
        </CarouselWrapper>

        <Box className="row" {...row}>
          <SocialProfile items={Data.dataJson.SOCIAL_PROFILES} />
        </Box>
      </Container>
    </SectionWrapper>
  )
}

// News style props
NewsSection.propTypes = {
  row: PropTypes.object,
}

// News default style
NewsSection.defaultProps = {
  // News row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    mt: "70px",
    justifyContent: "center",
  },

  sectionHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  // sub section default style
  sectionSubTitle: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center",
  },
  // section title default style
  sectionTitle: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "#b6b6b7",
    lineHeight: "1.75",
    textAlign: "justify",
    mb: "5%",
    mt: "5%",
  },
}
export default NewsSection
