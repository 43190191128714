/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Box from "../../../components/Box"
import Image from "../../../components/Image"
import { ClientsImage } from "./style"

const OurPartnersLanding = ({
  row,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        CLIENTS_LANDING {
          title
          url
          image {
            publicURL
          }
        }
      }
    }
  `)

  return (

    <Box {...row}>
      {Data.dataJson.CLIENTS_LANDING.map((item, index) => (
        <a key={index} href={item.url} target="_blank">

          <ClientsImage key={`client-${index}`}>
            {item.title === "Yanogo" ?
              <Image
                src={item.image.publicURL}
                alt={item.title}
                title={item.title}
                className='img-flex'
              /> : <Image
                src={item.image.publicURL}
                alt={item.title}
                title={item.title}

              />}

          </ClientsImage>
        </a>
      ))}
    </Box>

  )
}

OurPartnersLanding.propTypes = {
  row: PropTypes.object,
}

OurPartnersLanding.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    ml: ['0%', '0%', '0%', '0%', '10%', '10%'],
    mr: ['0%', '0%', '0%', '0%', '10%', '10%']

  },
}

export default OurPartnersLanding
