/* eslint-disable */

import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import Image from "../../../components/Image"
import FeatureBlock from "../../../components/FeatureBlock"
import { useStaticQuery, graphql } from "gatsby"
import { FeaturesWrapper, SectionImage, SectionSlider } from "./features.style"
import ImageOne from "../../../assets/images/generic/Phone.png"
import check from "../../../assets/images/landing/check.png"
import Tabs, { TabPane } from "rc-tabs"
import TabContent from "rc-tabs/lib/TabContent"
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar"
import "rc-tabs/assets/index.css"

const Features = ({
  title,
  FeatureItemStyle,
  iconstyle,
  featureDescription,
  sectionSubTitle,
  sectionTitle,
  sectionWrapper,
  iconDimention,
  imageSizes,
  imageSizes2,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        FEATURES_USERS {
          firstTitle
          secTitle
          thirdTitle
          description {
            description1
            description2
            description3
            description4
            description5
            description6
            description7
            description8
            description9
            description10
            description11
            id
            title
            phone {
              publicURL
            }
            image {
              publicURL
            }
          }
        }
      }
    }
  `)
  const {
    firstTitle,
    secTitle,
    thirdTitle,
    description,
  } = Data.dataJson.FEATURES_USERS

  return (
    <FeaturesWrapper id="features">
      <div className="flex-b1" mobilegutter='true' nogutter='true'>
        <div {...sectionWrapper}>
          <Fade bottom cascade>
            <Heading
              content={firstTitle}
              className="title-flex"
              {...sectionSubTitle}
            />
            <Text content={secTitle} className="text-flex" {...sectionTitle} />
            <Text
              content={thirdTitle}
              className="text-flex"
              {...sectionTitle}
            />
          </Fade>
        </div>
      </div>
      <div className="flex-b2" id="tab">
        <SectionSlider>
          <Tabs
            renderTabBar={() => <ScrollableInkTabBar />}
            renderTabContent={() => <TabContent animated={false} />}
            className="update-screen-tab"
          >
            {description.map((item, index) => (
              <TabPane
                forceRender={true}
                tab={
                  <Image
                    src={item.image.publicURL}
                    alt="not found"
                    // width="100px"
                    className="img-flex"
                  />
                }
                key={index + 1}
              >
                <div className="flexCols">
                  <div className="flexCols-b1">
                    <div className="cols-prod">
                      <SectionImage>
                        <Image
                          src={item.phone.publicURL}
                          {...imageSizes}
                          alt="VisitorDashboard1"
                        />
                        <Image
                          src={ImageOne}
                          {...imageSizes2}
                          alt="VisitorDashboard1"
                        />
                      </SectionImage>
                    </div>
                  </div>
                  <div className="flexCols-b2">
                    <div>
                      <Heading content={item.title} {...title} />
                    </div>
                    <Box key={`feature-item-${index}`} className="flex-desc">
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description1 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description1}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description2 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description2}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description3 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description3}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description4 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description4}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description5 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description5}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description6 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description6}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description7 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description7}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description8 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description8}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description9 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description9}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description10 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description10}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                      <FeatureBlock
                        className="flex-wrap"
                        icon={
                          item.description11 !== "" && (
                            <Image src={check} {...iconDimention} alt="" />
                          )
                        }
                        wrapperstyle={FeatureItemStyle}
                        iconstyle={iconstyle}
                        description={
                          <Heading
                            content={item.description11}
                            {...featureDescription}
                            className='text-size-min'
                          />
                        }
                      />
                    </Box>
                  </div>
                </div>
              </TabPane>
            ))}
          </Tabs>
        </SectionSlider>
      </div>
    </FeaturesWrapper>
  )
}

Features.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  col: PropTypes.object,
  FeatureItemStyle: PropTypes.object,
  iconstyle: PropTypes.object,
  contentStyle: PropTypes.object,
  featureDescription: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  text: PropTypes.object,
  thirdText: PropTypes.object,
  sectionWrapper: PropTypes.object,
  iconDirection: PropTypes.object,
  iconDimention: PropTypes.object,
}

Features.defaultProps = {
  sectionWrapper: {
    id: "feature_section",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  textArea: {
    width: ["100%", "100%", "45%"],
    ml: [0, 0, "58%"],
  },
  imageWrapper: {
    boxShadow: "none",
  },
  imageSizes: {
    mt: ["0%", "0%", "0%", "-2%", "-10%", "0%"],
    width: "50.2%",
    ml: "9%",
  },
  imageSizes2: {
    mt: "-93%",
  },
  title: {
    fontSize: ["30px", "37px", "37px", "42px", "42px", "46px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-1px",
    mb: ["0%", "-35px", "0px", "0px", "0px", "0px"],
    maxWidth: ["100%", "100%", "100%", "100%", "100%"],
    lineHeight: "3",
  },

  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
  },
  col1: {
    width: ["100%", "100%", "100%", "100%", "100%", "100%"],
    position: "relative",
    mb: ["0%", "0%", "24%", "0%", "0%", "0%"],
    height: "1000px",
  },
  col2: {
    width: ["100%", "100%", "95%", "100%", "56%", "30%"],
    ml: ["0%", "0%", "5%", "2%", "38%", "33%"],
    mt: ["-250%", "-50%", "-68%", "-122%", "-69%", "-50%"],
    height: "1000px",
  },
  FeatureItemStyle: {},
  iconstyle: {
    width: "30px",
    height: "30px",
    mr: "3%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconDirection: {
    width: "10px",
    height: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconDimention: {
    mt: ["3px", "3px", "3px", "7px", "7px", "7px"],
    maxWidth: ["50%", "50%", "65%", "100%", "100%", "100%"],
  },
  featureDescription: {
    display: "flex",
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "#b5b5b6",
    lineHeight: "1.75",
    textAlign: "left",
    mb: ["10px", "13px"],
    maxWidth: "718px",
  },

  sectionSubTitle: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center",
  },
  sectionTitle: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "#b6b6b7",
    lineHeight: "1.75",
    textAlign: "justify",
    mt: "5%",
    mb: "5%",
  },
}

export default Features
