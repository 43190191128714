/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import Button from "../../../components/Button"
import Image from "../../../components/Image"
import Fade from "react-reveal/Fade"
import {
  PartnerSectionWrapper,
  CustomerDownload,
  CustomerWrapper,
} from "./style"
import devices from "../../../assets/images/landing/devices.png"
import qr from "../../../assets/images/landing/qr.png"
import android from "../../../assets/images/landing/and.png"
import apple from "../../../assets/images/landing/io.png"
import { Link } from "gatsby"

const PartnerSection = ({
  row,
  col,
  header,
  sectionHeader,
  btnCallToAction,
  textArea,
  imageArea,
  sectionSubTitle,
  sectionTitle,
  imageDownload,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        ANDROID_IOS {
          android
          ios
        }
      }
    }
  `)
  return (
    <PartnerSectionWrapper>
      <Box {...row} className="downloadContainer">
        <Box {...textArea}>
          <div {...sectionHeader}>
            <Fade bottom cascade>
              <Text content="Für alle Geräte verfügbar:" {...header} />
              <Text
                {...sectionSubTitle}
                content="Desktop, iOS, Android & Tablet"
              />
              <Heading
                {...sectionTitle}
                className="showHide"
                content="Für die Agrabiz App benötigen Sie keine zusätzliche Hardware: Nutzen Sie einfach Ihr aktuelles Smartphone oder Tablet-Gerät mit iOS oder Android! Scannen Sie dazu einfach den unteren Code und werden Sie automatisch auf den App Store weitergeleitet für eine sekundenschnelle Installation."
              />
              <Heading
                {...sectionTitle}
                className="showHide"
                content="Und die Anzeige im Web-Browser braucht nicht einmal eine Installation: Klicken Sie von Ihrem Laptop oder Desktop-PC aus dem Büro einfach hier und starten Sie los."
              />
            </Fade>
          </div>
          <Box {...imageDownload} className="hideShow">
            <Image src={devices} alt="Devices" />
          </Box>
          <Box className="showQr showHide">
            <Image src={qr} alt="qr" />
          </Box>
          <Fade up delay={100}>
            <CustomerWrapper>
              <div className="banner">
                <div className="bannerDownload">
                  <a
                    href={Data.dataJson.ANDROID_IOS.ios}
                    // className="ancher"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      className="app_download_area"
                      variant="textButton"
                      icon={
                        <img src={apple} alt="apple" className="img_wrapper" />
                      }
                      iconposition="left"
                    />
                  </a>
                  <a
                    href={Data.dataJson.ANDROID_IOS.android}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      className="app_download_area"
                      variant="textButton"
                      icon={
                        <img
                          src={android}
                          alt="android"
                          className="img_wrapper"
                        />
                      }
                      iconposition="left"
                    />
                  </a>
                </div>
                <Link to="/register">
                  <CustomerDownload>
                    <Button
                      variant="downloadSection"
                      title="Jetzt kostenlos starten"
                      {...btnCallToAction}
                    />
                  </CustomerDownload>
                </Link>
              </div>
            </CustomerWrapper>
          </Fade>
        </Box>
        <Box {...col} {...imageArea} className="showHide">
          <Image src={devices} alt="Devices" />
        </Box>
      </Box>
    </PartnerSectionWrapper>
  )
}

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  btnCallToAction: PropTypes.object,
}

PartnerSection.defaultProps = {
  sectionHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  // sub section default style
  sectionSubTitle: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center",
    m: "15%",
  },
  // section title default style
  sectionTitle: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "#b6b6b7",
    lineHeight: "1.75",
    textAlign: "justify",
    m: "15%",
    mt: "3%",
    mb: ["10%", "3%"],
  },
  header: {
    color: "#b5b5b6",
    fontSize: ["17px", "17px", "20.5px", "27.5px", "35.5px", "35.5px"],
    lineHeight: "55px",
    fontWeight: "700",
    width: "100%",
    height: "100%",
    marginBottom: "24px",
    textAlign: "center !important",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "0%",
    mr: ["0", "0", "0", "-10%"],
    alignItems: "center",
  },
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  imageDownload: {
    flexDirection: "row-reverse",
  },
  col: {
    marginLeft: "5%",
    marginRight: "-10%",
    pr: "2%",
    pl: "2%",
  },
  textArea: {
    width: ["100%", "100%", "55%", "50%", "42%"],
  },
  imageArea: {
    width: ["100%", "100%", "45%", "50%", "58%"],
    mb: ["40px", "40px", "0", "0", "0"],
  },
  title: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center",
    m: "5%",
  },

  btnCallToAction: {
    fontSize: ["20px", "20px", "20px", "26.5px", "28.5px", "28.5px"],
  },
}

export default PartnerSection
