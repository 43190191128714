import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from 'gatsby';
import Image from "../../../components/Image"
import Fade from "react-reveal/Fade"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import Card from "../../../components/Card"
import Button from "../../../components/Button"
import AboutUsSectionWrapper, { CustomerDownload } from "./aboutUsSection.style"
import { Link } from 'gatsby';
import partner from "../../../assets/images/landing/partners.png"
import fpartner from "../../../assets/images/landing/AboutUs.png"

const AboutUsSection = ({
  row,
  title,
  description,
  textArea,
  btnCallToAction
}) => {
  return (
    <AboutUsSectionWrapper id="about">
      <Box className="row" {...row}>
        <Card className="group-gallery">
          <Box className="col1">
            <Fade left delay={30}>
              <Image src={fpartner} alt="Feature Image" />
            </Fade>
          </Box>
          <Box className="col2">
            <Fade bottom delay={90}>
              <Image src={partner} alt="Feature Image" />
            </Fade>
          </Box>
          <Box {...textArea} className="col3">
            <Heading content="Über uns" {...title} />
            <Text
              content="Agrabiz ist ein Produkt von der Branche für die Branche, denn unser gesamtes Team teilt die gemeinsame Leidenschaft für Innovation und echten Mehrwert für die Landwirtschaft. Erfahren Sie mehr über unsere Gründer, unsere Geschichte und unseren Anspruch."
              {...description}
            />


            <Link to="/about">
              <CustomerDownload>

                <Button
                  variant="custumBtn"
                  iconposition="right"
                  title="Erfahren Sie mehr"
                />

              </CustomerDownload>
            </Link>
          </Box>
        </Card>
      </Box>
    </AboutUsSectionWrapper>
  )
}

AboutUsSection.propTypes = {
  row: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnCallToAction: PropTypes.object
}

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    marginTop: "-28.6%",
  },
  // About us section text area default style
  textArea: {
    margin: "15%",
    maxWidth: ["auto", "50%"],
  },
  // About us section title default style
  title: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "48.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center !important",
  },
  description: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    marginTop: "10%",
    marginBottom: "10%",
    color: "#b5b5b6",
    lineHeight: "1.75",
    textAlign: "justify",
  },

  btnCallToAction: {
    fontSize: ['15px', '15px', '15px', '20.5px', '28.5px', '28.5px'],
  },
}

export default AboutUsSection
