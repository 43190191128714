/* eslint-disable */

import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import Button from "../../../components/Button"
import Card from "../../../components/Card"
import Image from "../../../components/Image"
import user from "../../../assets/images/landing/user.png"

import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from "../../../components/Accordion"
import { Icon } from "react-icons-kit"
import { thinUp } from "react-icons-kit/entypo/thinUp"
import { thinDown } from "react-icons-kit/entypo/thinDown"
import FaqSectionWrapper from "./faqSection.style"

const FaqSection = ({
  sectionWrapper,
  subtitleSection,
  header,
  titleSection,
  titleStyle,
  descriptionStyle,
  buttonWrapper,
  button,
  imageWrapper,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        FAQ {
          id
          title
          desc1
          desc2
          desc3
          desc4
          desc5
          expend
        }
      }
    }
  `)

  return (
    <FaqSectionWrapper id="faq_section">
      <div className="flex-b1">
        <Box>
          <div {...sectionWrapper}>
            <Fade bottom cascade>
              <Text content="Noch offene Fragen?" {...header} />
              <Text
                {...subtitleSection}
                className="title-flex"
                content="Kontaktieren Sie uns gerne."
              />
              <Text
                {...titleSection}
                className="text-flex"
                content="Hier finden Sie erste Antworten auf die wichtigsten Fragen rundum Agrabiz. Falls Sie noch weitere oder tiefergehende Fragen haben, freuen wir uns auf Ihren Kontakt!"
              />
            </Fade>
          </div>
          <Box>
            <Accordion>
              {Data.dataJson.FAQ.map((faqItem, index) => (
                <AccordionItem key={index} expanded={faqItem.expend}>
                  <Fragment>
                    <AccordionTitle>
                      <Fragment>
                        <Heading
                          content={faqItem.title}
                          {...titleStyle}
                          className="flex-title-box"
                        />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon icon={thinUp} size={18} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={thinDown} size={18} />
                          </CloseIcon>
                        </IconWrapper>
                      </Fragment>
                    </AccordionTitle>
                    <AccordionBody>
                      <Text content={faqItem.desc1} {...descriptionStyle} />
                      <Text
                        content={faqItem.desc2}
                        {...descriptionStyle}
                        className="margin-btw"
                      />
                      <Text
                        content={faqItem.desc3}
                        {...descriptionStyle}
                        className={faqItem.id === 5 ? "margin-btw" : ""}
                      />
                      <Text content={faqItem.desc4} {...descriptionStyle} />
                      <Text content={faqItem.desc5} {...descriptionStyle} />
                    </AccordionBody>
                  </Fragment>
                </AccordionItem>
              ))}
              <a href="javascript:zE.activate();">
                <div {...buttonWrapper}>
                  <Button
                    variant="faq"
                    {...button}
                    title="Kontaktieren Sie uns"
                  />
                </div>
              </a>
            </Accordion>
          </Box>
        </Box>
      </div>
      <div className="flex-b2">
        <Box className="faq-section-img">
          <Card {...imageWrapper}>
            <Fade right delay={90}>
              <Image
                src={user}
                alt="Feature Image"
                className="flex-img-wrapper"
              />
            </Fade>
          </Card>
        </Box>
      </div>
    </FaqSectionWrapper>
  )
}

// FaqSection style props
FaqSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  subtitleSection: PropTypes.object,
  titleSection: PropTypes.object,
  header: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageArea: PropTypes.object,
  imageWrapper: PropTypes.object,
}

// FaqSection default style
FaqSection.defaultProps = {
  sectionWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  // sub section default style
  subtitleSection: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center",
  },
  // section title default style
  titleSection: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "#b6b6b7",
    lineHeight: "1.75",
    textAlign: "justify",
    mt: "5%",
    mb: "5%",
  },
  // sub section default style
  sectionSubTitle: {
    content: "FREQUENTLY ASKED QUESTIONS",
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "0.15em",
    fontWeight: "700",
    color: "#5268db",
    mb: "5px",
  },
  // section title default style
  sectionTitle: {
    content: "Want to ask something about us ?",
    textAlign: "center",
    fontSize: ["20px", "24px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: "0",
  },
  // accordion title default style
  title: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center !important",
  },
  description: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "500",
    marginTop: "5%",
    marginBottom: "8%",
    color: "#b5b5b6",
    lineHeight: "1.75",
    textAlign: "justify",
    m: ["5%", "5%", "6%", "9%", "15%"],
  },

  titleStyle: {
    fontSize: ["16px", "17.5px", "19.5px", "22.5px", "22.5px", "24.5px"],
    fontWeight: "300",
    color: "#333333",
    letterSpacing: "-0.025em",
    mb: "0",
    ml: "0",
  },
  // accordion description default style
  descriptionStyle: {
    fontSize: ["12px", "13.5px", "14.5px", "16.5", "16.5px", "17.5px"],
    fontWeight: "500",
    color: "#959595",
    lineHeight: "1.45",
    textAlign: "left",
    mb: "0",
    fontWeight: "400",
  },
  buttonWrapper: {
    mt: `${14}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    display: "flex",
    fontSize: ["17px", , "24px", "28px", "28px", "28px", "28px"],
    fontWeight: "700",
    mt: "5%",
    ml: "3%",
  },
  header: {
    color: "#b5b5b6",
    fontSize: ["17px", "17px", "20.5px", "27.5px", "35.5px", "35.5px"],
    lineHeight: "55px",
    fontWeight: "700",
    width: "100%",
    height: "100%",
    marginBottom: "24px",
    textAlign: "center !important",
  },
  imageAreaRow: {
    flexDirection: "row-reverse",
  },
  imageArea: {
    mb: ["40px", "40px", "0", "0", "0"],
    flexBox: true,
    flexDirection: "row-reverse",
  },

  imageWrapper: {
    boxShadow: "none",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
}

export default FaqSection
