import styled from "styled-components"
import { themeGet } from "styled-system"

const PartnerSectionWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 120px 0; */
  @media only screen and (max-width: 425px) {
    padding: 0;
  }
  .downloadContainer {
    @media only screen and (max-width: 1024px) {
      margin-top: 0%;
    }

    @media only screen and (max-width: 425px) {
      margin-left: -5px;
      margin-right: 15px;
      margin-top: 0%;
      padding: 0;
    }
  }
  .showQr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1%;
    visibility: visible;
    @media (max-width: 1440px) {
      margin-left: 4%;
    }
  }
  .showHide {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .hideShow {
    display: none;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      display: inline;
    }
  }
  .banner {
    flex-direction: column;
  }
  .bannerDownload {
    display: flex;
    align-items: center;
    justify-content: center;
    .app_download_area {
      width: 90%;
      margin-right: 27px;
      margin-left: 25px;
      margin-top: 4%;
      margin-bottom: 4%;
      border-radius: 12px;
      @media only screen and (max-width: 1440px) {
        width: 95%;
      }
      @media only screen and (max-width: 1024px) {
        width: 95%;
        margin-left: 16px;
      }
      @media only screen and (max-width: 768px) {
        margin-left: 8%;
        width: 85%;
      }
      @media only screen and (max-width: 425px) {
        margin-left: 16%;
        width: 75%;
      }
      @media only screen and (max-width: 375px) {
        margin-left: 8%;
        width: 88%;
      }
      @media only screen and (max-width: 320px) {
        margin-left: 10%;
        width: 88%;
      }
    }
    .img_wrapper {
      width: 55%;
      height: 90%;
      margin-left: 0%;
      @media only screen and (max-width: 1366px) {
        width: 65%;
        height: 80%;
      }
      @media only screen and (max-width: 425px) {
        width: 81%;
        margin-top: 15%;
      }
    }
  }
`
const CustomerDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 126%;
  margin-top: 6%;
  margin-left: -12%;
  @media only screen and (max-width: 1440px) {
    width: 130%;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: -12.6%;
  }
  @media only screen and (max-width: 768px) {
    width: 122.5%;
    margin-left: -10.5%;
  }
  @media (max-width: 425px) {
    width: 116%;
    margin-left: -6%;
  }
  @media (max-width: 375px) {
    width: 126%;
    margin-left: -12%;
    margin-top: 12%;
  }
  @media (max-width: 320px) {
    margin-left: -11%;
  }
`
const CustomerWrapper = styled.div`
  display: flex;
  max-width: 850px;
  padding: 0px 0 70px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    padding: 0px 0 55px;
  }
  @media only screen and (max-width: 1099px) {
    padding: 55px 0 55px;
    max-width: 920px;
  }

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }
  @media only screen and (max-width: 600px) {
    padding: 25px 0 40px;
  }
  @media only screen and (max-width: 375px) {
    padding: 25px 0 40px;
    margin-left: 1.5%;
  }

  p {
    margin: 0 12px 0 0;
    color: ${themeGet("colors.menu", "#0D233E")};
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
`
export { PartnerSectionWrapper, CustomerDownload, CustomerWrapper }
