/* eslint-disable */
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import GlideCarousel from "../../../components/GlideCarousel"
import GlideSlide from "../../../components/GlideCarousel/glideSlide"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Image from "../../../components/Image"
import Heading from "../../../components/Heading"
import Container from "../../../components/Container"
import bannerImg from "../../../assets/images/landing/Phone.png"
import android from "../../../assets/images/landing/and.png"
import apple from "../../../assets/images/landing/io.png"
import down from "../../../assets/images/landing/down.png"
import video from "../../../assets/images/landing/video.png"
import ModalVideo from "./modal.video"
import BannerWrapper, {
  BannerContent,
  BannerImage,
  CustomerWrapper,
  ImageWrapper,
  TestimonialSlideWrapper,
  TestimonialItem,
  TestimonialMeta,
  AuthorInfo,
  Down,
} from "./style"

const TestimonialSection = ({ reviewTitle, name, designation }) => {
  const [open, setOpen] = useState(false)

  const refreshWidth = () => {
    setwidthSize(window.innerWidth)
    setheightSize(window.innerHeight)
  }
  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }

  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        BANNER_SLIDER {
          designation
          id
          title
          name
        }
        ANDROID_IOS {
          android
          ios
        }
      }
    }
  `)

  const carouselOptions = {
    type: "slider",
    autoplay: 5000,
    perView: 1,
    gap: 30,
    animationDuration: 800,
    breakpoints: {
      990: {
        perView: 1,
      },
    },
  }

  return (
    <BannerWrapper id="home" display="flex">
      <Container fullWidth>
        <BannerContent>
          <TestimonialSlideWrapper>
            <GlideCarousel
              options={carouselOptions}
              carouselSelector="testimonial__slider"
              controls={false}
              bullets={true}
              numberOfBullets={Data.dataJson.BANNER_SLIDER.length}
            >
              <>
                {Data.dataJson.BANNER_SLIDER.map((item, index) => (
                  <GlideSlide key={`testimonial-slide-${index}`}>
                    <TestimonialItem>
                      <Text as="h2" content={item.title} {...reviewTitle} />

                      <TestimonialMeta>
                        <AuthorInfo>
                          <Box>
                            <Heading as="h1" content={item.name} {...name} />
                            <Heading
                              as="h3"
                              content={item.designation}
                              {...designation}
                            />
                          </Box>
                        </AuthorInfo>
                      </TestimonialMeta>
                    </TestimonialItem>
                  </GlideSlide>
                ))}
              </>
            </GlideCarousel>
          </TestimonialSlideWrapper>
        </BannerContent>
        <BannerImage>
          <div className="animated animatedFadeInUp fadeInUp">
            <Image src={bannerImg} alt="Banner" />
          </div>
        </BannerImage>
      </Container>
      <Down>
        <div className="animated animatedFadeInUp fadeInUp">
          <a>
            <Image src={down} alt="Banner" />
          </a>
        </div>
      </Down>

      <CustomerWrapper>
        <ImageWrapper>
          <div className="bannerImageBtn">
            <button className=" banner-btn-video" onClick={openModal}>
              <img src={video} alt="video" width="60%" className="flex-btn" />
            </button>

            <ModalVideo open={open} closeModal={closeModal} />
            <a
              href={Data.dataJson.ANDROID_IOS.ios}
              // className="ancher"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="app_image_area banner-btn-download">
                <img src={apple} alt="apple" className="flex-btn" />
              </button>
            </a>
            <a
              href={Data.dataJson.ANDROID_IOS.android}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="app_image_area banner-btn-download">
                <img src={android} alt="android" className="flex-btn" />
              </button>
            </a>
          </div>
        </ImageWrapper>
      </CustomerWrapper>
    </BannerWrapper>
  )
}

export default TestimonialSection
