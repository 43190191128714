import styled from "styled-components"

const FeatureSectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  @media (max-width: 1440px) {
    padding: 40px 0 100px;
  }
  @media (max-width: 768px) {
    padding: 40px 0 10px;
  }
  @media (max-width: 500px) {
    padding: 30px 0;
  }
  @media (max-width: 375px) {
    margin-bottom: 0%;
  }

  .div-flex {
    margin-left: 6%;
    margin-right: 6%;
    margin-bottom: 6%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-between;

  }
  .div-flex-block {
  width:22%;
  @media (min-width: 800px) and (max-width: 1200px) {
    width:50%;
    justify-content: space-around;

  }
  @media (min-width: 200px) and (max-width: 800px) {
    width:80%;
  }
  }
  .react-reveal {
    display: flex;
    justify-content: center;
  }
  .text-flex {

    width: calc(45% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      justify-content: center;
      width: 35%;
    }
    @media (max-width: 425px) {
      justify-content: center;
      width: 70%;
    }
  }
  .title-flex {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1425px) {
      width: 50%;
    }
    @media (max-width: 1285px) {
      width: 55%;
    }

    @media (max-width: 500px) {
      width: 70%;
    }
    @media (max-width: 400px) {
      width: 80%;
    }
    @media (max-width: 335px) {
      width: 90%;
    }
  }

  .feature__block {
    position: relative;
    width: 300px;
    height: 300px;
    margin-bottom: 40px;
    transition: box-shadow 0.3s ease;
    border-radius: 10px;
    box-shadow: 0.7px 0px 27px 0 rgba(194, 207, 224, 0.73);
    @media (max-width: 425px) {
      height: 300px;
    }
    .icon__wrapper {
      width: 175px;
      height: 200px;
      position: relative;
      background: transperent;
      margin-bottom : 7%;
      .flaticon-flask {
        &:before {
          margin-left: 8px;
        }
      }

      &:before {
        transform: rotate(45deg);
        background-color: rgba(255, 255, 255, 0.15);
      }
      &:after {
        transform: rotate(-45deg);
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    &:hover {
      box-shadow: 0.5 40px 90px -30px rgba(194, 207, 224, 1.5);
      cursor: pointer;
      height: 300px;
      @media (max-width: 425px) {
        height: 300px;
      }
      .icon__wrapper {
        display: none;
      }
      .showHide {
        display: inline-block;
      }
      .justified-flex {
        display: flex;
        justify-content: "center";
        align-items: "center";
        text-align: "center";
        @media (max-width: 425px) {
          text-align: justify;
        }
      }
      .icon__wrapper {
        width: 148px;
        height: 160px;
      }
      .hoveredText {
        display: none;
      }
    }
  }
  .showHide {
    display: none;
  }
  .row {
    > .col {
      &:nth-child(1) {
        .feature__block {
          .icon__wrapper {

            color: #29cf8a;
            transition: all 0.6s ease;
          }
        }
      }
      &:nth-child(2) {
        .feature__block {
          .icon__wrapper {
            color: #ff86ab;
            transition: all 0.6s ease;
          }
        }
      }
      &:nth-child(3) {
        .feature__block {
          .icon__wrapper {
            color: #ff9000;
            transition: all 0.6s ease;
          }
        }
      }
    }
    .button__wrapper {
      bottom: 10px;
      text-align: center;
      margin: 0 auto;
    }
  }
`
export default FeatureSectionWrapper
