import React from "react"
import PropTypes from "prop-types"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import Container from "../../../components/Container"
import OurPartnersLanding from "../../Global/OurPartnersLanding"
import Fade from "react-reveal/Fade"
import {Partner} from "./style"

const PartnersSection = ({
  sectionHeader,
  sectionSubTitle,
  sectionTitle,
  sectionWrapper,
}) => {
  return (
    <Partner>
    <Box {...sectionWrapper} as="section">
      <Box {...sectionHeader} className='flex-box'>
        <Fade bottom cascade>
          <Text {...sectionSubTitle} className='title-flex' content="Unsere Partner für Innovation" />
          <Heading
            {...sectionTitle}
            className='text-flex'
            content="Wir freuen uns, starke Innovationspartner an unserer Seite zu haben: Unser Vorhaben wurde mit freundlicher Unterstützung des Förderprogramms der Deutschen Innovationspartnerschaft Agrar des Bundesministeriums für Ernährung und Landwirtschaft (BMEL) und einer Teilfinanzierung aus Mitteln des Zweckvermögens des Bundes der Landwirtschaftlichen Rentenbank realisiert."
          />
        </Fade>
      </Box>
      <Container nogutter width="1200px">
        <Fade bottom>
          <OurPartnersLanding />
        </Fade>
      </Container>
    </Box>
    </Partner>
  )
}

PartnersSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  sectionTitle: PropTypes.object,
}

PartnersSection.defaultProps = {
  sectionWrapper: {
    pt: ["140px", "60px", "0", "80px", "80px"],
    pb: ["60px", "80px", "100px", "130px", "130px"],
  },
  sectionHeader: {
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  // sub section default style
  sectionSubTitle: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center",
  },
  // section title default style
  sectionTitle: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "#b6b6b7",
    lineHeight: "1.75",
    textAlign: "justify",
    mb: "5%",
    mt: "5%",
  },
}

export default PartnersSection
