/* eslint-disable */
import React, { useEffect, useState } from "react"

const ClientRender = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  return children
}

export default ClientRender
