import styled from "styled-components"

export const ClientsImage = styled.div`
  position: relative;
  padding: 20px 14px;
  width: 230px;
  flex-shrink: 0;
  &:hover {
    img {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }

  img {
    filter: grayscale(0);
    opacity: 1;
    transition: 0.3s ease-in-out;
  }
`

export const Partner = styled.div`
  .react-reveal {
    display: flex;
    justify-content: center;
  }
  .text-flex {
    width: calc(45% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      justify-content: center;
      width: 35%;
    }
    @media (max-width: 425px) {
      justify-content: center;
      width: 70%;
    }
  }
  .title-flex {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1425px) {
      width: 50%;
    }
    @media (max-width: 1285px) {
      width: 55%;
    }

    @media (max-width: 500px) {
      width: 70%;
    }
    @media (max-width: 400px) {
      width: 80%;
    }
    @media (max-width: 335px) {
      width: 90%;
    }
  }
  .flex-box {
    @media (max-width: 425px) {
      margin-top: -60%;
    }
  }
`
