/* eslint-disable */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import FeatureBlock from "../../../components/FeatureBlock"
import Image from "../../../components/Image"
import FeatureSectionWrapper from "./style"
import Fade from "react-reveal/Fade"

const BenefitSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconstyle,
  contentStyle,
  blockWrapperStyle,
  featureBtn,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        BENEFITS_DATA {
          title
          description
          items {
            id
            title
            description
            icon {
              publicURL
            }
            btn
          }
        }
      }
    }
  `)

  const { title, description, items } = Data.dataJson.BENEFITS_DATA
  return (
    <FeatureSectionWrapper id="benefits">
      <div {...sectionHeader}>
        <Fade bottom cascade>
          <Text {...sectionSubTitle} className="title-flex" content={title} />
          <Heading
            {...sectionTitle}
            className="text-flex"
            content={description}
          />
        </Fade>
      </div>
      <div className="div-flex">
        {items.map((feature, index) => (
          <Fade key={index} bottom cascade>
            <div className="col div-flex-block" {...col} key={index}>
              <FeatureBlock
                icon={
                  <Image
                    src={feature.icon.publicURL}
                    alt="not found"
                    width={
                      feature.id === 1
                        ? "185px"
                        : feature.id === 3
                        ? "140px"
                        : feature.id === 4
                        ? "165px"
                        : "150px"
                    }
                    maxWidth="200% !important"
                  />
                }
                iconposition="top"
                wrapperstyle={blockWrapperStyle}
                iconstyle={iconstyle}
                contentStyle={contentStyle}
                title={
                  <Heading
                    content={feature.title}
                    {...featureTitle}
                    className="hoveredText"
                  />
                }
                description={
                  <Text
                    content={feature.description}
                    {...featureDescription}
                    className="showHide justified-flex"
                  />
                }
                button={
                  <Text
                    content={feature.btn}
                    {...featureBtn}
                    className="showHide"
                  />
                }
              />
            </div>
          </Fade>
        ))}
      </div>
    </FeatureSectionWrapper>
  )
}

// BenefitSection style props
BenefitSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
  featureBtn: PropTypes.object,
}

// BenefitSection default style
BenefitSection.defaultProps = {
  // section header default style
  sectionHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  // sub section default style
  sectionSubTitle: {
    fontSize: ["17px", "17px", "24px", "30px", "40.5px", "40.5px"],
    fontWeight: "700",
    color: "#98e044",
    letterSpacing: "-0.025em",
    lineHeight: "1.5",
    textAlign: "center",
  },
  // section title default style
  sectionTitle: {
    fontSize: ["14px", "15.5px", "17.5px", "18.5", "18.5px", "20.5px"],
    fontWeight: "350",
    color: "#b6b6b7",
    lineHeight: "1.75",
    textAlign: "justify",
    mb: "5%",
    mt: "5%",
  },

  // feature row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: ["3%", "7%", "5%", "1.2%", "3%", "3%"],
    mt: "20px",
    mb: ["-200%", "-200%", "0%", "-10%", "-6%", "0%"],
    height: "530px",
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 4, 1 / 4],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    // p: ["20px", "20px", "20px", "25px"],
  },
  // feature icon default style
  iconstyle: {
    width: "84px",
    height: "84px",
    m: "35px auto",
    bg: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "40px",
    color: "#29cf8a",
    overflow: "hidden",
    // mb: ["20px", "20px", "20px", "55px"],
    mt: "2%",
  },
  // feature content default style
  contentStyle: {
    textAlign: "center",
  },
  // feature title default style
  featureTitle: {
    fontSize: ["19px", "19px", "19px", "21px", "23px"],
    fontWeight: "bold",
    color: "#98e044",
    lineHeight: "1.39",
    mb: ["10px", "10px", "10px", "20px"],
    letterSpacing: "normal",
  },
  // feature description default style
  featureDescription: {
    fontSize: ["14px", "13.5px", "13.5px", "13.5px", "16.5px"],
    lineHeight: "1.65",
    color: "#b5b5b6",
    mb: "8%",
    ml: "8%",
    mr: "8%",
    pt: "12%",
  },
  // feature btn style
  featureBtn: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "1.66",
    textAlign: "center",
    color: "#98e044",
    ml: "15%",
    mr: "15%",
  },
}

export default BenefitSection
