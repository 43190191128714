import React from "react"
import { ThemeProvider } from "styled-components"
import Sticky from "react-stickynode"
import { theme } from "../theme"
import { GlobalStyle, ContentWrapper } from "../containers/style"
import { ResetCSS } from "../assets/css/style"
import Navbar from "../containers/Global/Navbar"
import BannerSection from "../containers/Landing/Banner"
import BenefitSection from "../containers/Landing/Benefits"
import FeatureSection from "../containers/Landing/Features"
//import TestimonialSection from "../containers/Landing/Testimonial"
import NewsSection from "../containers/Landing/News"
import PartnerSection from "../containers/Landing/Partners"
import FooterSection from "../containers/Global/Footer"
import DownloadSection from "../containers/Landing/Download"
import AboutUsSection from "../containers/Landing/AboutUsSection"
import FaqSection from "../containers/Landing/FaqSection"
import Cookies from "../containers/Landing/Cookies"
import ClientRender from "../containers/Landing/ClientRender/ClientRender"

import { DrawerProvider } from "../contexts/DrawerContext"
import { ParallaxProvider } from "react-scroll-parallax"
import SEO from "../components/seo"

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <SEO title="Agrabiz" />
        <ResetCSS />
        <GlobalStyle />
        <ClientRender>
          <Cookies />
        </ClientRender>

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <ClientRender>
                <Navbar />
              </ClientRender>
            </DrawerProvider>
          </Sticky>
          <ClientRender>
            <BannerSection />

            <BenefitSection />
            <FeatureSection />
            <DownloadSection />
            <AboutUsSection />
            <NewsSection />
            <FaqSection />
            <PartnerSection />
            <FooterSection />
          </ClientRender>
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  )
}
