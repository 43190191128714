import styled from "styled-components"

export const ClientsImage = styled.div`
  position: relative;
  padding: 20px 14px;
  width: 250px;
  @media (max-width: 768px) {
    width: 200px;
  }
  @media (max-width: 768px) {
    width: 170px;
  }
  @media (max-width: 425px) {
    width: 150px;
  }
  @media (max-width: 375px) {
    width: 120px;
  }
  flex-shrink: 0;
  &:hover {
    img {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  img {
    filter: grayscale(1);
    opacity: 0.5;
    transition: 0.3s ease-in-out;
  }
  .img-flex {
    width: 200px;
    @media (max-width: 425px) {
      width: 100px;
    }
  }
`
